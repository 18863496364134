/* eslint-disable react/require-default-props */
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

interface IDropdownClient {
    id: string;
    value?: any;
    onChange?: any;
    className?: any;
    disabled?: boolean;
    placeholder?: string;
}

function DropdownCurrency({ id, value, onChange, className, disabled, placeholder }: IDropdownClient, ref: any) {
    const options = [
        { name: 'GBP', _id: 'GBP' },
        { name: 'USD', _id: 'USD' },
        { name: 'EUR', _id: 'EUR' },
        { name: 'CLIENT CURRENCY', _id: 'ClientCountryCurrency' },
        { name: ' ORIGINAL VALUE', _id: 'OriginalValue' }
    ];

    const onChangeCurrency = (e: any) => {
        onChange(e);
    };

    return (
        <div className="w-full">
            <Dropdown
                ref={ref}
                filter
                showClear
                filterBy="name"
                id={id}
                value={value}
                disabled={disabled}
                onChange={onChangeCurrency}
                options={options}
                optionLabel="name"
                className={className}
                placeholder={placeholder && placeholder !== '' ? placeholder : 'Select One'}
            />
        </div>
    );
}

export default React.forwardRef(DropdownCurrency);
