import { useEffect } from 'react';
import FullscreenLoaderAuth from './components/FullscreenLoaderAuth';

// eslint-disable-next-line react/prop-types
const Protected = ({ isLoggedIn, children }: any) => {
    useEffect(() => {
        if (isLoggedIn === false) {
            window.location.href = `${process.env.REACT_APP_REVERB_REACT_URL}/login`;
        }
    }, [isLoggedIn]);

    if (!isLoggedIn) {
        return <FullscreenLoaderAuth />;
    }
    return children;
};
export default Protected;
