// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width: 767px) {\n  .SalesmanMonthlyCommissionReport_centeContent__JyUo3 {\n    min-width: -webkit-fill-available;\n  }\n}", "",{"version":3,"sources":["webpack://./src/pages/admin/reports/SalesmanMonthlyCommissionReport.module.scss"],"names":[],"mappings":"AAAC;EACG;IACI,iCAAA;EACN;AACF","sourcesContent":[" @media screen and (max-width: 767px) {\n    .centeContent{\n        min-width: -webkit-fill-available\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centeContent": "SalesmanMonthlyCommissionReport_centeContent__JyUo3"
};
export default ___CSS_LOADER_EXPORT___;
