const FullscreenLoaderAuth = () => {
    return (
        <div className="splash-screen">
            <h2>Checking Authorization</h2>
            <div className="splash-loader-container">
                <svg className="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="splash-path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30" />
                </svg>
            </div>
        </div>
    );
};
export default FullscreenLoaderAuth;
