/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { RTLContext } from './App';

const AppFooter = (props: any) => {
    const isRTL = useContext(RTLContext);

    return <div className="layout-footer flex align-items-center p-4 shadow-2">soreto</div>;
};

export default AppFooter;
