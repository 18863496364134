/* eslint-disable react/destructuring-assignment */
import { CSSTransition } from 'react-transition-group';
import { classNames } from 'primereact/utils';
import { useRef } from 'react';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

const AppInlineMenu = (props: any) => {
    const menuKey = props.menuKey || 'inline-menu';
    const inlineMenuRef = useRef(null);

    const inlineMenuClassName = classNames(
        'layout-inline-menu',
        {
            'layout-inline-menu-active': props.inlineMenuActive[props.menuKey]
        },
        props.className
    );

    const isSlim = () => {
        return props.menuMode === 'slim';
    };

    return (
        <div className={inlineMenuClassName} style={props.style}>
            <CSSTransition nodeRef={inlineMenuRef} classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={props.inlineMenuActive[menuKey]} unmountOnExit>
                <>
                    <ul ref={inlineMenuRef} className="layout-inline-menu-action-panel">
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Settings">
                            <Button className="flex flex-row align-items-center p-link">
                                <i className="pi pi-cog pi-fw" />
                                <span>Settings</span>
                            </Button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Terms of Usage">
                            <Button className="flex flex-row align-items-center p-link">
                                <i className="pi pi-file-o pi-fw" />
                                <span>Terms of Usage</span>
                            </Button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Support">
                            <Button className="flex flex-row align-items-center p-link">
                                <i className="pi pi-compass pi-fw" />
                                <span>Support</span>
                            </Button>
                        </li>
                        <li className="layout-inline-menu-action-item tooltip" data-pr-tooltip="Logout">
                            <Button className="flex flex-row align-items-center p-link">
                                <i className="pi pi-power-off pi-fw" />
                                <span>Logout</span>
                            </Button>
                        </li>
                    </ul>
                    {isSlim() && <Tooltip target=".tooltip" />}
                </>
            </CSSTransition>
        </div>
    );
};

export default AppInlineMenu;
