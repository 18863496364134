import BaseService from './BaseService';

export default class BreakageService extends BaseService {
    static getAll(query: string, params?: any) {
        return this.get('reports/breakage', query, params)
            .then((res) => res.data.resultData)
            .catch((res) => {
                throw res?.response?.data;
            });
    }
}
