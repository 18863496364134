import BaseService from './BaseService';

export default class ClientService extends BaseService {
    static getAll(query: string) {
        return this.get('clients/listings/all', query);
    }

    static getAllWithReward(query: string) {
        return this.get('clients/listing/giftCardReward', query);
    }
}
