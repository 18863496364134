/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import ClientService from '../service/ClientService';

interface IDropdownClient {
    id: string;
    value?: any;
    onChange?: any;
    className?: any;
    disabled?: boolean;
    placeholder?: string;
    singleSelect?: boolean;
    reward?: boolean;
}

interface IValue {
    _id: string;
    name: string;
    singleSelect: boolean;
}

function DropdownClients({ id, value, onChange, className, disabled, placeholder, singleSelect, reward }: IDropdownClient, ref: any) {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        if (reward) {
            ClientService.getAllWithReward('?$sort=name').then((response) => {
                const clientsArray = response.data.resultData.map((client: any) => ({
                    name: client.name,
                    _id: client._id
                }));
                setClients(clientsArray);
            });
        } else {
            ClientService.getAll('?$sort=name').then((response) => {
                const clientsArray = response.data.map((client: any) => ({
                    name: client.name,
                    _id: client._id
                }));
                setClients(clientsArray);
            });
        }
    }, []);

    const onChangeClient = (e: any) => {
        onChange(e);
    };

    if (singleSelect) {
        return (
            <div className="w-full">
                <Dropdown
                    ref={ref}
                    filter
                    showClear
                    filterBy="name"
                    id={id}
                    value={value}
                    disabled={disabled}
                    onChange={onChangeClient}
                    options={clients}
                    optionLabel="name"
                    className={className}
                    placeholder={placeholder && placeholder !== '' ? placeholder : 'Select One'}
                />
            </div>
        );
    }

    const getVisibilityTagFromIds = (arrayOfIds: any[]) => {
        if (arrayOfIds.length > 0) {
            const arrayOfVisibilityTags = (arrayOfIds as unknown as IValue[]).map((visibilityId) => {
                return clients.find((currentVisibility: any) => currentVisibility._id === visibilityId);
            });
            return arrayOfVisibilityTags;
        }
        return [];
    };

    let selectedVisibilityTag: IValue[] | any;

    if (!value || !value.length || value[0] === null || (value.length === 1 && !value[0])) {
        selectedVisibilityTag = [];
    } else if (value[0]?._id) {
        selectedVisibilityTag = value;
    } else {
        selectedVisibilityTag = getVisibilityTagFromIds(value);
    }
    return (
        <div>
            <MultiSelect filter showClear filterBy="name" id={id} value={selectedVisibilityTag} onChange={onChangeClient} options={clients} optionLabel="name" placeholder="Select Client" />
        </div>
    );
}

export default React.forwardRef(DropdownClients);
