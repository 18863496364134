/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Button } from 'primereact/button';
import { RTLContext } from './App';

const AppTopbar = (props: any) => {
    const isRTL = useContext(RTLContext);
    const navigate = useNavigate();

    const topbarRef3 = useRef(null);
    const topbarRef4 = useRef(null);

    // Fixed for 6.1.0
    // eslint-disable-next-line
    const searchPanel = useRef(null);

    useEffect(() => {
        // Fixed for 6.1.0
        /* if (props.searchActive) {
            searchPanel.current.element.focus();
        } */
    }, [props.searchActive]);

    return (
        <div className="layout-topbar shadow-4">
            <div className="layout-topbar-left">
                <div style={{ cursor: 'pointer' }} className="layout-topbar-logo p-link" onClick={() => navigate('/')}>
                    <img id="app-logo" src="assets/layout/images/soreto-logo-dark.svg" alt="ultima-layout" style={{ height: '2.25rem' }} />
                </div>
                <div className="layout-menu-button shadow-6 p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-chevron-right" />
                </div>
                <Button type="button" className="layout-topbar-mobile-button p-link">
                    <i className="pi pi-ellipsis-v fs-large" onClick={props.onMobileTopbarButtonClick} />
                </Button>
            </div>

            <div
                className={classNames('layout-topbar-right', {
                    'layout-topbar-mobile-active': props.mobileTopbarActive
                })}
            >
                {/* <div className="layout-topbar-actions-left" />
                <div className="layout-topbar-actions-right">
                    <ul className="layout-topbar-items">
                        <li className="layout-topbar-item app">
                            <Button
                                className="layout-topbar-action rounded-circle p-link"
                                onClick={(event) =>
                                    props.onTopbarItemClick({
                                        originalEvent: event,
                                        item: 'apps'
                                    })
                                }
                            >
                                <i className="pi pi-table fs-large" />
                            </Button>

                            <CSSTransition nodeRef={topbarRef3} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'apps'} unmountOnExit>
                                <div ref={topbarRef3} className="layout-topbar-action-panel shadow-6">
                                    <div className="grid grid-nogutter">
                                        <div className="layout-topbar-action-item col-4">
                                            <Button className="flex align-items-center flex-column text-color p-link">
                                                <i className="pi pi-file-pdf action orange-bgcolor white-color" />
                                                <span>Reports</span>
                                            </Button>
                                        </div>
                                        <div className="layout-topbar-action-item col-4">
                                            <Button className="flex align-items-center flex-column text-color p-link">
                                                <i className="pi pi-cog action pink-bgcolor white-color" />
                                                <span>Settings</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </CSSTransition>
                        </li>
                        <li className="layout-topbar-item">
                            <Button
                                className="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-link"
                                onClick={(event) =>
                                    props.onTopbarItemClick({
                                        originalEvent: event,
                                        item: 'profile'
                                    })
                                }
                            >
                                <img src="assets/layout/images/default-avatar.png" alt="avatar" style={{ width: '32px', height: '32px' }} />
                            </Button>

                            <CSSTransition nodeRef={topbarRef4} classNames="p-toggleable" timeout={{ enter: 1000, exit: 450 }} in={props.activeTopbarItem === 'profile'} unmountOnExit>
                                <ul ref={topbarRef4} className="layout-topbar-action-panel shadow-6">
                                    <li className="layout-topbar-action-item">
                                        <Button className="flex flex-row align-items-center p-link">
                                            <i
                                                className={classNames('pi pi-cog', {
                                                    'mr-2': !isRTL,
                                                    'ml-2': isRTL
                                                })}
                                            />
                                            <span>Settings</span>
                                        </Button>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <Button className="flex flex-row align-items-center p-link">
                                            <i
                                                className={classNames('pi pi-file', {
                                                    'mr-2': !isRTL,
                                                    'ml-2': isRTL
                                                })}
                                            />
                                            <span>Terms of Usage</span>
                                        </Button>
                                    </li>
                                    <li className="layout-topbar-action-item ">
                                        <Button className="flex flex-row align-items-center p-link">
                                            <i
                                                className={classNames('pi pi-compass', {
                                                    'mr-2': !isRTL,
                                                    'ml-2': isRTL
                                                })}
                                            />
                                            <span>Support</span>
                                        </Button>
                                    </li>
                                    <li className="layout-topbar-action-item">
                                        <Button className="flex flex-row align-items-center p-link">
                                            <i
                                                className={classNames('pi pi-power-off', {
                                                    'mr-2': !isRTL,
                                                    'ml-2': isRTL
                                                })}
                                            />
                                            <span>Logout</span>
                                        </Button>
                                    </li>
                                </ul>
                            </CSSTransition>
                        </li>
                    </ul>
                </div> */}
            </div>
        </div>
    );
};

export default AppTopbar;
