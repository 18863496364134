import axios from 'axios';

const { REACT_APP_API_URL } = process.env;
export default class BaseService {
    /**
     * This is the basic get function
     * @param endpoint
     * @param query
     * @returns
     */
    static get(endpoint: string, query?: string, params?: any) {
        return axios.get(`${REACT_APP_API_URL}/${endpoint}${query}`, {
            withCredentials: true,
            params
        });
    }
}
