const appAllowedRoles = (): string[] => {
    const configAllowedRoles = process.env.REACT_APP_ALLOWED_ROLES;

    if (configAllowedRoles) {
        return configAllowedRoles.split(',');
    }

    return [];
};

export default {
    appAllowedRoles
};
