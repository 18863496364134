/* eslint-disable react/require-default-props */
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import SalespersonService from '../service/SalespersonService';

interface IDropdownSalesperson {
    id: string;
    value: any;
    onChange(e: Event): void;
    singleSelect?: boolean;
}

interface IValue {
    _id: string;
    name: string;
    singleSelect: boolean;
}
const DropdownSalesperson = ({ id, value, onChange, singleSelect }: IDropdownSalesperson) => {
    const [salespersons, setSalespersons] = useState<IValue[]>([]);
    const getSalespersons = async () => {
        try {
            await SalespersonService.getAll().then((response) => {
                if (response) {
                    const listArray = response.data.map((item: any) => ({
                        name: item.firstName,
                        _id: item._id
                    }));
                    setSalespersons(listArray);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        getSalespersons();
    }, []);

    const onChangeSalesPerson = (e: any) => {
        onChange(e);
    };

    const getVisibilityTagFromIds = (arrayOfIds: any[]) => {
        if (arrayOfIds.length > 0) {
            const arrayOfVisibilityTags = (arrayOfIds as unknown as IValue[]).map((visibilityId) => {
                return salespersons.find((currentVisibility: any) => currentVisibility._id === visibilityId);
            });
            return arrayOfVisibilityTags;
        }
        return [];
    };

    if (singleSelect) {
        const selectedSalesperson = value?._id ? value : salespersons.find((item: any) => item._id === value);
        return <Dropdown filter showClear filterBy="name" id={id} value={selectedSalesperson} onChange={onChangeSalesPerson} options={salespersons} optionLabel="name" placeholder="Select Salesperson" />;
    }

    let selectedVisibilityTag: IValue[] | any;

    if (!value || !value.length || value[0] === null || (value.length === 1 && !value[0])) {
        selectedVisibilityTag = [];
    } else if (value[0]?._id) {
        selectedVisibilityTag = value;
    } else {
        selectedVisibilityTag = getVisibilityTagFromIds(value);
    }
    return (
        <div>
            <MultiSelect filter showClear filterBy="name" id={id} value={selectedVisibilityTag} onChange={onChangeSalesPerson} options={salespersons} optionLabel="name" placeholder="Select Salesperson" />
        </div>
    );
};

export default React.forwardRef(DropdownSalesperson);
