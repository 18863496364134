/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-self-assign */
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useRef, useState } from 'react';
import moment from 'moment';
import SalesmanMonthlyCommissionService from '../../../service/SalesmanMonthlyCommissionService';
import DropdownClients from '../../../components/DropdownClients';
import DropdownSalesperson from '../../../components/DropdownSalesperson';
import DropdownCurrency from '../../../components/DropdownCurrency';
import classes from './SalesmanMonthlyCommissionReport.module.scss';

const SalesmanMonthlyCommissionReport = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [salesmanMonthlyComissions, setsalesmanMonthlyComissions] = useState<any>(null);
    const [salesmanMonthlyComissionsOriginal, setsalesmanMonthlyComissionsOriginal] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [clientIds, setClientIds] = useState([]);
    const [currencyId, setCurrencyId] = useState({ name: 'GBP', _id: 'GBP' });
    const [salespersonIds, setSalespersonIds] = useState([]);
    const [fromDate, setFromDate] = useState(moment().startOf('month').format());
    const [toDate, setToDate] = useState(moment().endOf('month').format());
    const [fieldTotalValueSales, setFieldTotalValueSales] = useState('totalValueSales_GBP');
    const [fieldSalesCommission, setFieldSalesCommission] = useState('salesCommission_GBP');
    const [fieldPendingSalesCommission, setFieldPendingSalesCommission] = useState('pendingSalesCommission_GBP');
    const [fieldPaidSalesCommission, setFieldPaidSalesCommission] = useState('paidSalesCommission_GBP');
    const [fieldDeclinedSalesCommission, setFieldDeclinedSalesCommission] = useState('declinedSalesCommission_GBP');
    const formatterNumber = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });
    const toast = useRef<any>(null);

    const formatterGBP = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const formatterGeneric = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const formatterUSD = {
        format: (value: any) => {
            return `$${formatterGeneric.format(value)}`;
        }
    };

    const formatterEUR = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const formatterOriginalValue = new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    const [totals, setTotals] = useState({
        clientSales: 0,
        offerClicks: 0,
        soretoTraffic: 0,
        countSoretoSales: 0,
        countSoretoSalesPending: 0,
        countSoretoSalesPaid: 0,
        countSoretoSalesDeclined: 0,
        totalValueSales: '0',
        totalValueSoretoSales: '0',
        totalValueSoretoSalesPending: '0',
        totalValueSoretoSalesPaid: '0',
        totalValueSoretoSalesDeclined: '0',
        totalValueRawCommission: '0',
        totalValueSoretoCommission_USD: '0',
        totalValueSoretoCommission_CLI_CURRENCY: '0',
        totalValueSoretoCommission: '0',
        totalValueSoretoCommissionPending: '0',
        totalValueSoretoCommissionPaid: '0',
        totalValueSoretoCommissionDeclined: '0',
        clicks: 0,
        shares: 0,
        shareRate: '0',
        purchaseRate: '0',
        reachMultiple: '0',
        interstitialRate: '0',
        conversionRate: '0'
    });

    const formatterNumberOneCase = new Intl.NumberFormat('en-GB', {
        maximumFractionDigits: 1,
        minimumFractionDigits: 1
    });

    const onChangeCurrency = (currency: any) => {
        if (currency._id !== 'OriginalValue') {
            setFieldTotalValueSales(`totalValueSales_${currency._id}`);
            setFieldSalesCommission(`salesCommission_${currency._id}`);
            setFieldPendingSalesCommission(`pendingSalesCommission_${currency._id}`);
            setFieldPaidSalesCommission(`paidSalesCommission_${currency._id}`);
            setFieldDeclinedSalesCommission(`declinedSalesCommission_${currency._id}`);
        } else {
            setFieldTotalValueSales('totalValueSales');
            setFieldSalesCommission('salesCommission');
            setFieldPendingSalesCommission('pendingSalesCommission');
            setFieldPaidSalesCommission('paidSalesCommission');
            setFieldDeclinedSalesCommission('declinedSalesCommission');
        }

        const campaignStats = JSON.parse(JSON.stringify(salesmanMonthlyComissionsOriginal));
        setCurrencyId(currency);

        setClientTotalStats({ data: { page: campaignStats } }, currency._id);
    };

    const dynamicFormatter = (currencyCode: any) => {
        const currencyFormatter: any = {};
        if (!currencyCode) {
            return formatterGeneric;
        }

        const f = currencyFormatter[currencyCode];

        if (f) {
            return f;
        }

        currencyFormatter[currencyCode] = new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return currencyFormatter[currencyCode];
    };

    const setClientTotalStats = (data: any, currency: any) => {
        const totalsTeste = {
            clientSales: 0,
            offerClicks: 0,
            soretoTraffic: 0,
            countSoretoSales: 0,
            countSoretoSalesPending: 0,
            countSoretoSalesPaid: 0,
            countSoretoSalesDeclined: 0,
            totalValueSales: 0,
            totalValueSoretoSales: 0,
            totalValueSoretoSalesPending: 0,
            totalValueSoretoSalesPaid: 0,
            totalValueSoretoSalesDeclined: 0,
            totalValueRawCommission: 0,
            totalValueSoretoCommission_USD: 0,
            totalValueSoretoCommission_CLI_CURRENCY: 0,
            totalValueSoretoCommission: 0,
            totalValueSoretoCommissionPending: 0,
            totalValueSoretoCommissionPaid: 0,
            totalValueSoretoCommissionDeclined: 0,
            clicks: 0,
            shares: 0,
            shareRate: 0,
            purchaseRate: 0,
            reachMultiple: 0,
            interstitialRate: 0,
            conversionRate: 0
        };

        let currencyFormater = null;

        switch (currency) {
            case 'GBP':
                currencyFormater = formatterGBP;
                break;
            case 'USD':
                currencyFormater = formatterUSD;
                break;
            case 'EUR':
                currencyFormater = formatterEUR;
                break;
            case 'OriginalValue':
                currencyFormater = formatterOriginalValue;
                break;
            default:
                currencyFormater = formatterGeneric;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const row of data.data.page) {
            let specificFormatter = null;

            if (currency === 'ClientCountryCurrency') {
                specificFormatter = dynamicFormatter(row.clientCountryCurrencyCode);
            } else {
                specificFormatter = currencyFormater;
            }

            totalsTeste.clientSales += row.clientSales;
            totalsTeste.offerClicks += row.offerClicks;
            totalsTeste.soretoTraffic += row.interstitialClicks;

            // SALES COUNT
            totalsTeste.countSoretoSales += row.soretoSales;
            totalsTeste.countSoretoSalesPending += row.pendingSalesCount;
            totalsTeste.countSoretoSalesPaid += row.paidSalesCount;
            totalsTeste.countSoretoSalesDeclined += row.declinedSalesCount;

            // SALES totalsTeste SUM
            if (currency === 'OriginalValue') {
                totalsTeste.totalValueSales += row.totalValueSales;
            } else {
                totalsTeste.totalValueSales += row[`totalValueSales_${currency}`];
            }

            totalsTeste.totalValueSoretoSales += row.totalValueSales;
            totalsTeste.totalValueSoretoSalesPending += row.totalValueSalesPending;
            totalsTeste.totalValueSoretoSalesPaid += row.totalValueSalesPaid;
            totalsTeste.totalValueSoretoSalesDeclined += row.totalValueSalesDeclined;

            // SALES COMMISSION SUM
            if (currency === 'OriginalValue') {
                totalsTeste.totalValueRawCommission += +row.salesCommission;
                totalsTeste.totalValueSoretoCommission_USD += +row.salesCommission_USD;
                totalsTeste.totalValueSoretoCommission_CLI_CURRENCY += +row.salesCommission_ClientCountryCurrency;
                totalsTeste.totalValueSoretoCommission += +row.salesCommission;
                totalsTeste.totalValueSoretoCommissionPending += +row.pendingSalesCommission;
                totalsTeste.totalValueSoretoCommissionPaid += +row.paidSalesCommission;
                totalsTeste.totalValueSoretoCommissionDeclined += +row.declinedSalesCommission;
            } else {
                totalsTeste.totalValueRawCommission += row.salesCommission;
                totalsTeste.totalValueSoretoCommission_USD += row.salesCommission_USD;
                totalsTeste.totalValueSoretoCommission_CLI_CURRENCY += row.salesCommission_ClientCountryCurrency;
                totalsTeste.totalValueSoretoCommission += row[`salesCommission_${currency}`];
                totalsTeste.totalValueSoretoCommissionPending += +row[`pendingSalesCommission_${currency}`];
                totalsTeste.totalValueSoretoCommissionPaid += +row[`paidSalesCommission_${currency}`];
                totalsTeste.totalValueSoretoCommissionDeclined += +row[`declinedSalesCommission_${currency}`];
            }

            // CLICKS
            totalsTeste.clicks += Number(row.refClicks);
            totalsTeste.shares += +row.shares;

            row.refClicks = Number(row.refClicks);
            row.pendingSalesCount = row.pendingSalesCount;
            row.paidSalesCount = row.paidSalesCount;
            row.declinedSalesCount = row.declinedSalesCount;

            row.purchaseRate = `${formatterNumber.format(row.purchaseRate)}%`;
            row.conversionRate = `${formatterNumber.format(row.conversionRate)}%`;
            row.shareRate = `${formatterNumber.format(row.shareRate)}%`;
            row.reachMultiple = formatterNumberOneCase.format(row.reachMultiple);
            row.interstitialClicksRate = `${formatterNumber.format(row.interstitialClicksRate)}%`;

            row.totalValueSoretoSales = row.totalValueSoretoSales;
            row.totalValueSoretoSalesPending = specificFormatter.format(row.pendingSales);
            row.totalValueSoretoSalesPaid = specificFormatter.format(row.paidSales);
            row.totalValueSoretoSalesDeclined = specificFormatter.format(row.declinedSales);

            if (currency === 'OriginalValue') {
                row.totalValueSales = specificFormatter.format(row.totalValueSales);
                row.salesCommission = specificFormatter.format(row.salesCommission);
                row.pendingSalesCommission = specificFormatter.format(row.pendingSalesCommission);
                row.paidSalesCommission = specificFormatter.format(row.paidSalesCommission);
                row.declinedSalesCommission = specificFormatter.format(row.declinedSalesCommission);
            } else {
                row[`totalValueSales_${currency}`] = specificFormatter.format(row[`totalValueSales_${currency}`]);
                row.salesCommission = formatterNumber.format(row.salesCommission);
                row[`salesCommission_${currency}`] = specificFormatter.format(row[`salesCommission_${currency}`]);
                row[`pendingSalesCommission_${currency}`] = specificFormatter.format(row[`pendingSalesCommission_${currency}`]);
                row[`paidSalesCommission_${currency}`] = specificFormatter.format(row[`paidSalesCommission_${currency}`]);
                row[`declinedSalesCommission_${currency}`] = specificFormatter.format(row[`declinedSalesCommission_${currency}`]);
            }

            if (row.eventDateTerm) {
                row.eventDateTerm = row.eventDateTerm;
            } else {
                row.eventDateTerm = moment(fromDate).startOf('month').format('YYYY-MM');
            }
        }

        /**
         * FORMAT
         */

        totals.countSoretoSales = totalsTeste.countSoretoSales;
        totals.purchaseRate = `${formatterNumber.format(+totalsTeste.soretoTraffic > 0 ? (+totalsTeste.countSoretoSales / +totalsTeste.soretoTraffic) * 100 : 0)}%`;
        totals.conversionRate = `${formatterNumber.format(+totalsTeste.clicks > 0 ? (+totalsTeste.countSoretoSales / +totalsTeste.clicks) * 100 : 0)}%`;
        totals.shareRate = `${formatterNumber.format(+totalsTeste.clientSales > 0 ? (+totalsTeste.shares / +totalsTeste.clientSales) * 100 : 0)}%`;
        totals.reachMultiple = formatterNumberOneCase.format(+totalsTeste.shares > 0 ? +totalsTeste.clicks / +totalsTeste.shares : 0);
        totals.interstitialRate = `${formatterNumber.format(+totalsTeste.clicks > 0 ? (+totalsTeste.soretoTraffic / +totalsTeste.clicks) * 100 : 0)}%`;

        totals.totalValueSales = currencyFormater.format(totalsTeste.totalValueSales);
        totals.totalValueSoretoSales = totalsTeste.totalValueSoretoSales.toFixed(0);
        totals.totalValueSoretoSalesPending = currencyFormater.format(totalsTeste.totalValueSoretoSalesPending);
        totals.totalValueSoretoSalesPaid = currencyFormater.format(totalsTeste.totalValueSoretoSalesPaid);
        totals.totalValueSoretoSalesDeclined = currencyFormater.format(totalsTeste.totalValueSoretoSalesDeclined);

        totals.totalValueRawCommission = currencyFormater.format(totalsTeste.totalValueRawCommission);
        totals.totalValueSoretoCommission_USD = formatterUSD.format(totalsTeste.totalValueSoretoCommission_USD);
        totals.totalValueSoretoCommission_CLI_CURRENCY = formatterNumber.format(totalsTeste.totalValueSoretoCommission_CLI_CURRENCY);
        totals.totalValueSoretoCommission = currencyFormater.format(totalsTeste.totalValueSoretoCommission);
        totals.totalValueSoretoCommissionPending = currencyFormater.format(totalsTeste.totalValueSoretoCommissionPending);
        totals.totalValueSoretoCommissionPaid = currencyFormater.format(totalsTeste.totalValueSoretoCommissionPaid);
        totals.totalValueSoretoCommissionDeclined = currencyFormater.format(totalsTeste.totalValueSoretoCommissionDeclined);
        totals.countSoretoSalesPending = totalsTeste.countSoretoSalesPending;
        totals.countSoretoSalesPaid = totalsTeste.countSoretoSalesPaid;
        totals.countSoretoSalesDeclined = totalsTeste.countSoretoSalesDeclined;

        setsalesmanMonthlyComissions(data.data.page);
    };

    const getSalesmanMonthlyComissions = async (query?: any, params?: any) => {
        try {
            setIsVisible(true);
            await SalesmanMonthlyCommissionService.getAll(query, params).then((data) => {
                const resOriginal = JSON.parse(JSON.stringify(data));
                setsalesmanMonthlyComissionsOriginal(resOriginal);
                setClientTotalStats({ data: { page: data } }, currencyId._id);
            });
        } catch (error: any) {
            setsalesmanMonthlyComissions([]);
            setTotals({
                clientSales: 0,
                offerClicks: 0,
                soretoTraffic: 0,
                countSoretoSales: 0,
                countSoretoSalesPending: 0,
                countSoretoSalesPaid: 0,
                countSoretoSalesDeclined: 0,
                totalValueSales: '0',
                totalValueSoretoSales: '0',
                totalValueSoretoSalesPending: '0',
                totalValueSoretoSalesPaid: '0',
                totalValueSoretoSalesDeclined: '0',
                totalValueRawCommission: '0',
                totalValueSoretoCommission_USD: '0',
                totalValueSoretoCommission_CLI_CURRENCY: '0',
                totalValueSoretoCommission: '0',
                totalValueSoretoCommissionPending: '0',
                totalValueSoretoCommissionPaid: '0',
                totalValueSoretoCommissionDeclined: '0',
                clicks: 0,
                shares: 0,
                shareRate: '0',
                purchaseRate: '0',
                reachMultiple: '0',
                interstitialRate: '0',
                conversionRate: '0'
            });
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Request failed for timeout',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const getQuery = () => {
        let query: any = {
            $offset: 0,
            $limit: 1000
        };

        const gteDate = fromDate ? { $date_$gte: moment(fromDate).startOf('month').format('YYYY-MM-DD') } : {};
        const lteDate = toDate ? { $date_$lte: moment(toDate).endOf('month').format('YYYY-MM-DD') } : {};
        query = {
            ...query,
            ...gteDate,
            ...lteDate,
            ...{
                showResponsible: true,
                showCampaignLevel: false,
                showCampaignVersionLevel: false,
                showCampaignVersionAliasLevel: false,
                showCampaignCountryLevel: false,
                showInactiveDays: true,
                apiVersion: 2,
                campaignVersionSourceTagGroup: false,
                socialPostSocialPlatform: false,
                dateGrouping: 'monthly',
                $daysDiffFromClientLaunch_$lte: 365
            }
        };

        if (clientIds && clientIds.length > 0) {
            query.clientIds = clientIds.map((item: any) => {
                return item._id;
            });
        }

        if (salespersonIds && salespersonIds.length > 0) {
            query.responsibleId = salespersonIds.map((item: any) => {
                return item._id;
            });
        }

        return query;
    };

    const onSubmit = async () => {
        setLoading(true);
        await getSalesmanMonthlyComissions('?$sort=eventDateTerm&', getQuery());
    };

    return (
        <div>
            <div className="card p-fluid">
                <Toast ref={toast} />
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="orderPostRewadClient">Salesperson</label>
                        <DropdownSalesperson id="salespersonId" value={salespersonIds} onChange={(e: any) => setSalespersonIds(e.value)} />
                    </div>
                    <div className="field col">
                        <label htmlFor="orderPostRewadClient">Client</label>
                        <DropdownClients id="clientId" value={clientIds} onChange={(e: any) => setClientIds(e.value)} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className={`field col-3 ${classes.centeContent}`}>
                        <label htmlFor="publishedDate">Start Date</label>
                        <Calendar dateFormat="mm/yy" id="fromDate" value={fromDate && new Date(fromDate)} onChange={(e: any) => setFromDate(e.value)} showOnFocus={false} view="month" showIcon />
                    </div>
                    <div className={`field col-3 ${classes.centeContent}`}>
                        <label htmlFor="publishedDate">End Date</label>
                        <Calendar dateFormat="mm/yy" id="toDate" value={toDate && new Date(toDate)} onChange={(e: any) => setToDate(e.value)} showOnFocus={false} view="month" showIcon />
                    </div>
                    <div className={`field col ${classes.centeContent}`}>
                        <label htmlFor="orderPostRewadClient">Currency</label>
                        <DropdownCurrency id="currencyId" value={currencyId} onChange={(e: any) => onChangeCurrency(e.value)} />
                    </div>
                </div>
                <div className="field col-2">
                    <Button label="Search" type="submit" onClick={onSubmit} />
                </div>
            </div>

            <div hidden={!isVisible} className="card">
                <h5>Salesperson commission</h5>

                <DataTable value={salesmanMonthlyComissions} scrollable scrollHeight="500px" loading={loading} scrollDirection="both" className="mt-3">
                    <Column field="clientResponsibleName" header="Salesperson" footer="Totals:" footerStyle={{ textAlign: 'right' }} style={{ flexGrow: 1, flexBasis: '160px' }} />
                    <Column field="clientName" header="Client" style={{ flexGrow: 1, flexBasis: '200px' }} alignFrozen="left" />
                    <Column field="eventDateTerm" header="Month" style={{ flexGrow: 1, flexBasis: '100px' }} />
                    <Column className="justify-content-center" field="soretoSales" header="Soreto Sales" footer={totals.countSoretoSales} style={{ flexGrow: 1, flexBasis: '105px' }} />
                    <Column className="justify-content-center" field="conversionRate" header="Conv. Rate" footer={totals.conversionRate} style={{ flexGrow: 1, flexBasis: '150px' }} />
                    <Column className="justify-content-center" field={fieldTotalValueSales} header="Revenue" footer={totals.totalValueSales} style={{ flexGrow: 1, flexBasis: '150px' }} />
                    <Column className="justify-content-center" field="pendingSalesCount" header="Pend #" footer={totals.countSoretoSalesPending} style={{ flexGrow: 1, flexBasis: '80px' }} />
                    <Column className="justify-content-center" field="paidSalesCount" header="Conf #" footer={totals.countSoretoSalesPaid} style={{ flexGrow: 1, flexBasis: '80px' }} />
                    <Column className="justify-content-center" field="declinedSalesCount" header="Decl #" footer={totals.countSoretoSalesDeclined} style={{ flexGrow: 1, flexBasis: '80px' }} />
                    <Column className="justify-content-center" field={fieldSalesCommission} header="Com. $" footer={totals.totalValueSoretoCommission} style={{ flexGrow: 1, flexBasis: '150px' }} />
                    <Column className="justify-content-center" field={fieldPendingSalesCommission} header="Com. Pen. $" footer={totals.totalValueSoretoCommissionPending} style={{ flexGrow: 1, flexBasis: '150px' }} />
                    <Column className="justify-content-center" field={fieldPaidSalesCommission} header="Com. Paid $" footer={totals.totalValueSoretoCommissionPaid} style={{ flexGrow: 1, flexBasis: '150px' }} />
                    <Column className="justify-content-center" field={fieldDeclinedSalesCommission} header="Com. Decl. $" footer={totals.totalValueSoretoCommissionDeclined} style={{ flexGrow: 1, flexBasis: '150px' }} />
                </DataTable>
            </div>
        </div>
    );
};

export default SalesmanMonthlyCommissionReport;
